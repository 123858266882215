import { breakpoints } from "./utilities.js";
export default () => {
	$('.m-banner:not(.js-banner-done)').each(function() {
		var wrapper = $(this);
		var gallery = wrapper.find('.js-gallery');

		gallery.find('.js-gallery-item').show();

		if (gallery.find('.js-gallery-video').length) {
			var item = gallery.find('.js-gallery-video');
			item.find('video').show().get(0).play();
		}
		else {
			// slider
			if (gallery.find('.js-gallery-item').length > 1) {

				// init
				var slider = gallery.bxSlider({
					mode: 'ontouchstart' in window && window.matchMedia('(max-width: ' + (breakpoints.tabletLarge - 1) + 'px)').matches ? 'horizontal' : 'fade',
					auto: true,
					speed: 500,
					pause: 3000,
					nextText: '<a class="bx-controls--next"><i class="fa fa-arrow-right"><i></a>',
					prevText: '<a class="bx-controls--prev"><i class="fa fa-arrow-left"><i></a>'
				});

				// interactions
				gallery.find('a').on('click', function() {
					slider.stopAuto();
				});
			}
		}

		// done
		wrapper.addClass('js-banner-done');
	});
}
